const getUrls = (): {
  redirectUrl: string
  jobsUrl: string
  apiUrl: string
  currentUrl: string
  pdfServerUrl: string
  userNotFoundRedirectUrl: string
  careerUrl: string
} => {
  let currentUrl = window.location.origin || process.env.REACT_APP_CURRENT_URL || ''

  if (!currentUrl.includes('https://') && !currentUrl.includes('http://')) {
    currentUrl = 'https://' + currentUrl
  }

  const redirectUrl = currentUrl + '/login'
  const userNotFoundRedirectUrl = currentUrl + '/user-not-found'

  const jobsUrl = process.env.REACT_APP_JOBS_URL || ''
  const careerUrl = process.env.REACT_APP_CAREER_URL || ''
  const apiUrl = process.env.REACT_APP_SERVER_URL || ''
  const pdfServerUrl = process.env.REACT_APP_PDF_SERVER_URL || ''

  return { currentUrl, redirectUrl, jobsUrl, apiUrl, pdfServerUrl, userNotFoundRedirectUrl, careerUrl }
}

export default getUrls
