import { graphql } from '@/graphql'

// prettier-ignore
const CompanyQuery = graphql(`
  query Company($id: ID!) {
    company(id: $id) {
      id
      ...CompanyParts
    }
  }
`)

export default CompanyQuery
