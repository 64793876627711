import { graphql, type ResultOf } from '@/graphql'

// prettier-ignore
export const InvitedUsersQuery = graphql(`
  query InvitedUsers($companyId: ID!, $limit: Int!, $after: String, $sortOrder: String, $sortBy: String) {
    invitedUsers(companyId: $companyId, limit: $limit, after: $after, sortOrder: $sortOrder, sortBy: $sortBy) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          createdAt
          firstName
          lastName
          email
          role {
            ...UserRoleParts
          }
        }
      }
    }
  }
`)
export type InvitedUser = NonNullable<ResultOf<typeof InvitedUsersQuery>['invitedUsers']['edges'][number]>['node']

// prettier-ignore
export const UserQuery = graphql(`
  query User($id: ID!) {
    user(id: $id) {
      ...UserParts
    }
  }
`)

// prettier-ignore
export const UsersQuery = graphql(`
  query Users($companyId: ID!, $recruitmentId: ID, $searchText: String, $includeSelf: Boolean, $limit: Int) {
    users(companyId: $companyId, recruitmentId: $recruitmentId, searchText: $searchText, includeSelf: $includeSelf, limit: $limit) {
      ...UserParts
    }
  }
`)

// prettier-ignore
export const MeQuery = graphql(`
  query Me {
    me {
      ...MeParts
    }
  }
`)

// prettier-ignore
export const UsersAdminQuery = graphql(`
  query UsersAdmin($page: Int, $pageSize: Int, $searchText: String, $sortOrder: String, $sortBy: String) {
    usersAdmin(page: $page, pageSize: $pageSize, searchText: $searchText, sortOrder: $sortOrder, sortBy: $sortBy) {
      totalCount
      items {
        ...UserParts
        userAgents {
          ...UserAgentParts
        }
      }
    }
  }
`)

export const PaginatedCompanyUsersQuery = graphql(`
  query PaginatedCompanyUsers($companyId: ID!, $page: Int, $pageSize: Int, $sortBy: String, $sortOrder: String, $searchText: String) {
    paginatedCompanyUsers(companyId: $companyId, page: $page, pageSize: $pageSize, sortBy: $sortBy, sortOrder: $sortOrder, searchText: $searchText) {
      items {
        ...UserParts
      }
      totalCount
    }
  }
`)

export const CompanyUsersQuery = graphql(`
  query CompanyUsers(
    $companyId: ID!
    $limit: Int!
    $after: String
    $sortOrder: String
    $includeSelf: Boolean
    $sortBy: String
    $onlyInvited: Boolean
    $searchText: String
    $showResultsFromParentCompanies: Boolean
  ) {
    companyUsers(
      companyId: $companyId
      limit: $limit
      after: $after
      sortOrder: $sortOrder
      includeSelf: $includeSelf
      sortBy: $sortBy
      onlyInvited: $onlyInvited
      searchText: $searchText
      showResultsFromParentCompanies: $showResultsFromParentCompanies
    ) {
      edges {
        cursor
        node {
          id
          ...UserParts
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`)
