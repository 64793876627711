import { type BreadcrumbsData } from '@/components/AppBar/Breadcrumbs'
import usePrevious from '@/hooks/usePrevious'
import { createContext, useCallback, useContext, useState, type FC, type ReactNode } from 'react'
import { withRouter, type RouteComponentProps } from 'react-router-dom'

export interface NavigationProviderValue {
  breadcrumbs: BreadcrumbsData | null
  previousParams: string
  setBreadcrumbs: (breadcrumbs: BreadcrumbsData | null) => void
}

const NavigationContext = createContext<NavigationProviderValue | null>(null)

const useNavigation = () => {
  const context = useContext(NavigationContext)
  if (!context) throw new Error('useNavigation must be used within a NavigationProvider')
  return context
}

const NavigationProvider: FC<RouteComponentProps & { children: ReactNode }> = ({ children, location: { search } }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsData | null>(null)

  // const handleSetPaths = useCallback((newPaths: PathData[]) => {
  //   if ((newPaths.length === 1 && newPaths[0]?.title === 'Hem') || newPaths.length === 0) {
  //     document.title = 'Cruitive'
  //   } else {
  //     const title = newPaths[newPaths.length - 1]?.title
  //     document.title = typeof title === 'string' ? `${title} | Cruitive` : 'Laddar...'
  //   }
  // }, [])

  const handleSetBreadcrumbs = useCallback((newBreadcrumbs: BreadcrumbsData | null) => {
    if (newBreadcrumbs == null) {
      document.title = 'Cruitive'
    }
    setBreadcrumbs(newBreadcrumbs)
  }, [])

  const previousParams = usePrevious(search)
  return (
    <NavigationContext.Provider
      value={{
        previousParams: previousParams || '',
        breadcrumbs,
        setBreadcrumbs: handleSetBreadcrumbs,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

const Provider = withRouter(NavigationProvider)
export { NavigationContext, Provider as NavigationProvider, useNavigation }
