import { cn } from '@/lib/utils'
import { useMemo, type CSSProperties, type FC } from 'react'
import './spinner.css'

interface Props {
  color?: 'white' | 'primary' | 'dark'
  size?: number
  className?: string
  style?: CSSProperties
}

const Spinner: FC<Props> = ({ color = 'primary', size = 30, className = '', style = {} }) => {
  const colorClass = useMemo(() => {
    if (color === 'white') {
      return 'text-foreground'
    } else if (color === 'primary') {
      return 'text-primary'
    } else if (color === 'dark') {
      return 'text-black'
    }
  }, [color])

  return (
    <svg style={{ width: size, height: size, ...style }} className={cn(className, colorClass, 'spinner')} viewBox='0 0 50 50'>
      <circle className='path stroke-current' cx='25' cy='25' r='20' fill='none' strokeWidth='5'></circle>
    </svg>
  )
}

export default Spinner
